import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";

import ProductSelect from "../../components/ProductSelect/product-select";
import Container from "../../components/Container/container";
import { NextCollection } from "../../components/ProductSelect/next-collection";

class YakSelect extends Component {
	render() {
		const domain =
			process.env.GATSBY_MYSHOPIFY_URL || "tearribles.myshopify.com";

		if (domain !== "tearribles.myshopify.com") {
			return null;
		}

		const chewData =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					return !node.tags.includes("Treats");
				},
			);

		const treatData =
			this.props.data.allShopifyCollection.edges?.[0]?.node.products.filter(
				(node) => {
					return node.tags.includes("Treats");
				},
			);

		return (
			<>
				<Container style={{ marginTop: "3.6rem" }}>
					<p>
						{`Be the first to try Baloomba Chews & Treats - a pawsome
						selection curated by our sister company, Baloomba
						Treats. Be the trailblazer in treating your furkids to
						the finest delights inspired by our unconditional love
						for our own dogs. From the irresistible all-natural
						Chickie Nugs, crafted in small batches for the pickiest
						pups, to the sturdy all-natural Himalayan Yak Chews
						built for the toughest chewers, our range is
						tail-waggingly diverse and growing every day. Your pet's
						happiness starts here - try Baloomba Chews & Treats
						today and discover a world of flavor tailored to every
						taste!`}
					</p>
				</Container>
				<ProductSelect
					title="Chews"
					{...this.props}
					data={{
						allShopifyCollection: {
							edges: [
								{
									node: {
										products: chewData,
									},
								},
							],
						},
					}}
				/>
				<ProductSelect
					title="Treats"
					{...this.props}
					data={{
						allShopifyCollection: {
							edges: [
								{
									node: {
										products: treatData,
									},
								},
							],
						},
					}}
				/>
				<NextCollection handleOverride="chews" {...this.props} />
			</>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query yakQuery {
				allShopifyCollection(
					filter: { handle: { eq: "baloomba-hard-chews" } }
				) {
					edges {
						node {
							title
							handle
							products {
								id
								shopifyId
								legacyResourceId
								title
								descriptionHtml
								productType
								tags
								handle
								collections {
									title
								}
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									shopifyId
									legacyResourceId
									title
									id
									sku
									price
									presentmentPrices {
										price {
											amount
											currencyCode
										}
									}
									compareAtPrice
									availableForSale
								}
							}
						}
					}
				}
			}
		`}
	>
		{(data) => <YakSelect data={data} {...props} />}
	</StaticQuery>
);
